import React, { useState } from "react";
import moment from "moment";

import "../styles/centered-box.less";

import { uuidv4, getLocalFormValues, navigate } from "../utils";

import axios from "axios";

import {
  Layout,
  DatePicker,
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
} from "antd";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";

import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";

const { Content } = Layout;

function disabledDate(current) {
  // Can not select days after today and today
  return current && current > moment().endOf("day");
}

function submitAnswer(newFormValues, setIsLoading) {
  setIsLoading(true);
  const { request_id: requestId, birthday } = newFormValues;
  console.log(newFormValues);
  localStorage.setItem(requestId, JSON.stringify(newFormValues));
  axios
    .post(process.env.GATSBY_API_URL + "/update-registration", newFormValues)
    .then(() => {
      const age = moment().diff(moment(birthday, "YYYY-MM-DD"), "years");
      if (age < 18) {
        return navigate(`/age-legal-non-atteint#${requestId}`);
      }
      navigate(`/form2-speed#${requestId}`);
    })
    .catch((e) => {
      message.error("Erreur lors de la communication : " + e.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
}

export default function IdentityForm({ location }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const initFormValues = location.state?.formValues || getLocalFormValues();

  if (!initFormValues) {
    /* clear of the localStorage + direct access to URL => go home */
    navigate("/");
    return null;
  }

  const { firstname, name, email, birthday, phone } = initFormValues;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  return (
    <Layout className="centered-box">
      <HeaderPatinette />
      <Content className="content">
        <Row type="flex" justify="center">
          <Col lg={18} xs={24} className="main">
            <h2>Et si on faisait connaissance ?</h2>
            <Row type="flex" justify="center" gutter={16}>
              <Col lg={12} xs={18}>
                <Form
                  form={form}
                  {...formItemLayout}
                  onFinish={(values) =>
                    submitAnswer(
                      {
                        request_id: uuidv4(),
                        ...initFormValues,
                        ...values,
                        birthday: values.birthday.format("YYYY-MM-DD"),
                      },
                      setIsLoading
                    )
                  }
                >
                  <Form.Item
                    name="firstname"
                    initialValue={firstname}
                    rules={[
                      {
                        required: true,
                        message: "Requis",
                      },
                    ]}
                    label={`Prénom`}
                  >
                    <Input disabled={isLoading} type="text" />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label={`Nom`}
                    initialValue={name}
                    rules={[
                      {
                        required: true,
                        message: "Requis",
                      },
                    ]}
                  >
                    <Input disabled={isLoading} type="text" />
                  </Form.Item>
                  <Form.Item
                    name="birthday"
                    initialValue={birthday && moment(birthday, "YYYY-MM-DD")}
                    style={{ textAlign: "left" }}
                    label={`Date de naissance`}
                    rules={[
                      {
                        required: true,
                        message: "Requis",
                      },
                    ]}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      disabledDate={disabledDate}
                      placeholder="JJ/MM/YYYY"
                      disabled={isLoading}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    initialValue={email}
                    rules={[
                      {
                        required: true,
                        message: "Requis",
                      },
                      {
                        type: "email",
                        message: "Requis",
                      },
                    ]}
                    label={`Email`}
                  >
                    <Input
                      addonBefore={<MailOutlined />}
                      type="email"
                      disabled={isLoading}
                    />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    initialValue={phone}
                    rules={[
                      {
                        required: true,
                        message: "Requis",
                      },
                    ]}
                    label={`Téléphone mobile`}
                  >
                    <Input
                      addonBefore={<PhoneOutlined />}
                      type="text"
                      disabled={isLoading}
                      placeholder="+33..."
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      style={{ marginTop: 30 }}
                      htmlType="submit"
                      type="primary"
                      size="large"
                      disabled={isLoading}
                      loading={isLoading}
                    >
                      Plus que 3 clics
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
